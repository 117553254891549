import Header from "./Components/Header";
import Main from "./Components/Main";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Contact from "./Pages/Contact";
import Interests from "./Pages/Interests";

import "./App.css";

// test
function App() {
  return (
    <>
      <Home></Home>
      <About></About>
    </>
  );
}

export default App;
