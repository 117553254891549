import React from "react";
import profileImage from "../Assets/profile.jpg";
// Import your styles here if you're using styled-components or CSS modules

function Home() {
  return (
    <div className="image-container">
      <img src={profileImage} alt="" className="profile-image" />
    </div>
  );
}

export default Home;
