import React from "react";
// Import your styles here if you're using styled-components or CSS modules

function About() {
  return (
    <div className="about-container">
      <h1>About</h1>
      <p>
        I'm <span className="bold">Jake Kohn</span>, a graduate student at the
        University of Pennsylvania. I am currently getting a Master's degree in
        Computer and Information Technology. This website is under development.
      </p>
    </div>
  );
}

export default About;
